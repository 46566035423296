<template>
	<section class="section section--profile">
        <h4 class="section__title">{{ $store.state.translation.profile.personal_title }}</h4>

        <div class="section__content">
            <form class="form form--profile form--personal-data" v-on:submit.prevent="updateItem">
                <div class="form__group form__group--file">
                    <div class="form__label">
                        <span>{{ $store.state.translation.profile.personal.load_avatar }}</span>
                        <label for="avatar-upload" class="file-upload">
                            <input 
                                @input="setAvatar" 
                                ref="avatar"                                 
                                accept=".png, .jpg, .jpeg, .gif"
                                type="file" 
                                id="avatar-upload" 
                                name="avatar" 
                                class="file-upload__input"/>

                            <div class="file-upload__field-wrapper">
                                <div class="file-upload__icon">
                                    <img src="../../../assets/img/icons/download.svg" alt="" />
                                </div>
                                <div class="file-upload__name" v-if="!avatar.name">{{ $store.state.translation.profile.personal.select_file }}</div>
                                <div class="file-upload__name cwhite" v-else>{{ avatar.name }}</div>
                            </div>
                            <button type="button" class="btn btn--purple file-upload__btn" @click="updateAvatar" :disabled="avatar_preloader">
                                <preloader v-if="avatar_preloader"/>
                                {{ $store.state.translation.profile.personal.load_avatar }}
                            </button>
                        </label>
                        <span class="error_input" v-if="errors.avatar">{{ errors.avatar }}</span>
                    </div>
                </div>

                <div class="form__group">
                    <label for="pd-input-email" class="form__label">
                        <span class="form_title">{{ $store.state.translation.profile.personal.email }}</span>
                        <input 
                            :disabled="$store.state.user.data.confirmations.email"
                            v-model="data.profile.email"
                            type="email" 
                            class="form__input" 
                            placeholder="mail@mail.com"/>
                    </label>
                </div>
                <div class="form__group">
                    <label for="pd-input-email" class="form__label">
                        <span class="form_title">{{ $store.state.translation.profile.personal.username }} <span class="red">*</span></span>
                        <input 
                            v-model="data.profile.username"
                            @input="errors.profile.username = false"
                            type="text" 
                            class="form__input" 
                            placeholder="player123"/>
                        <span class="error_input" v-if="errors.profile.username">{{ errors.profile.username }}</span>
                    </label>
                </div>
                <div class="form__group">
                    <label for="pd-input-email" class="form__label">
                        <span class="form_title">{{ $store.state.translation.profile.personal.lastname }} <span class="red">*</span></span>
                        <input 
                            v-model="data.profile.last_name"
                            @input="errors.profile.last_name = false"
                            type="text" 
                            class="form__input" 
                            :placeholder="$store.state.translation.profile.personal.lastname_placeholder"/>
                        <span class="error_input" v-if="errors.profile.last_name">{{ errors.profile.last_name }}</span>
                    </label>
                </div>
                <div class="form__group">
                    <label for="pd-input-email" class="form__label">
                        <span class="form_title">{{ $store.state.translation.profile.personal.firstname }} <span class="red">*</span></span>
                        <input 
                            v-model="data.profile.first_name"
                            @input="errors.profile.first_name = false"
                            type="text" 
                            class="form__input" 
                            :placeholder="$store.state.translation.profile.personal.firstname_placeholder"/>
                        <span class="error_input" v-if="errors.profile.first_name">{{ errors.profile.first_name }}</span>
                    </label>
                </div>
                <div class="form__group">
                    <label for="pd-input-email" class="form__label">
                        <span class="form_title">{{ $store.state.translation.profile.personal.surname }} <span class="red">*</span></span>
                        <input 
                            v-model="data.profile.surname"
                            @input="errors.profile.surname = false"
                            type="text" 
                            class="form__input" 
                            :placeholder="$store.state.translation.profile.personal.surname_placeholder"/>
                        <span class="error_input" v-if="errors.profile.surname">{{ errors.profile.surname }}</span>
                    </label>
                </div>

                <div class="form__group">
                    <label for="pd-input-tel" class="form__label">
                        <span class="form_title">{{ $store.state.translation.profile.personal.phone.title }} <span class="red">*</span></span>
                        <VuePhoneNumberInput
                            v-model="data.profile.phone.national"
                            @input="errors.profile.phone = false"
                            @update="phoneValidate"
                            :default-country-code="data.profile.phone.country_code"
                            :border-radius="0"
                            :translations="translations"/>
                        <span class="error_input" v-if="errors.profile.phone">{{ errors.profile.phone }}</span>
                    </label>
                </div>

                <div class="form__group">
                    <label for="pd-input-tel" class="form__label">
                        <span class="form_title">{{ $store.state.translation.profile.personal.sex }} <span class="red">*</span></span>
                        <v-select 
                            :options="utils.sex"
                            v-model="data.profile.sex"
                            @input="errors.profile.sex = false"
                            :placeholder="$store.state.translation.profile.personal.sex_m"
                            label="name"
                            class="big-vue-select account-vue-select">
                            <template v-slot:no-options="{ search, searching }">
                              <template>
                                 {{ $store.state.translation.select.nothing_found }}
                              </template>
                            </template>
                        </v-select>
                        <span class="error_input" v-if="errors.profile.sex">{{ errors.profile.sex }}</span>
                    </label>
                </div>

                <div class="form__group">
                    <label for="pd-input-tel" class="form__label">
                        <span class="form_title">{{ $store.state.translation.profile.personal.birthday }} <span class="red">*</span></span>
                        <input 
                            v-model="data.profile.birthday"
                            @input="errors.profile.birthday = false"
                            @change="birthdayValidate"
                            v-mask="'####-##-##'"
                            placeholder="1988-11-11"
                            maxlength="10"
                            type="text" 
                            class="form__input"/>
                        <span class="error_input" v-if="errors.profile.birthday">{{ errors.profile.birthday }}</span>
                    </label>
                </div>

                <button 
                    :disabled="loader_button"
                    type="submit" 
                    class="btn btn--fill-rose form__submit">
                    <preloader v-if="loader_button"/>
                    {{ $store.state.translation.profile.personal.save }}
                </button>
            </form>
        </div>
    </section>
</template>

<script>
    import CountryFlag from 'vue-country-flag'
    import VuePhoneNumberInput from 'vue-phone-number-input'
    import 'vue-phone-number-input/dist/vue-phone-number-input.css'
    export default {
        components: {
            CountryFlag,
            VuePhoneNumberInput
        },
        data() {
            return {
                loader_button: false,
                avatar_preloader: false,

                data: {
                    profile: {
                        username: null,
                        email: null,
                        first_name: null,
                        last_name: null,
                        surname: null,
                        sex: null,
                        birthday: null,
                        phone: {
                            country_code: null,
                            national: null,
                            data: null
                        }
                    }
                },
                avatar: {
                    name: null,
                    file: null
                },
                errors: {
                    profile: {
                        username: null,
                        email: null,
                        first_name: null,
                        last_name: null,
                        surname: null,
                        sex: null,
                        birthday: null,
                        phone: null
                    },
                    avatar: null
                },
                utils: {
                    countries: [],
                    sex: [
                        {
                            name: this.$store.state.translation.profile.personal.sex_m,
                            key: 1
                        },
                        {
                            name: this.$store.state.translation.profile.personal.sex_f,
                            key: 2
                        }
                    ]
                },
                translations: {
                    countrySelectorLabel: this.$store.state.translation.profile.personal.phone.code,
                    countrySelectorError: this.$store.state.translation.profile.personal.phone.country,
                    phoneNumberLabel: this.$store.state.translation.profile.personal.phone.number,
                    example: this.$store.state.translation.profile.personal.phone.example
                }
            }
        },
        methods: {
            updateItem () {
                if(!this.data.profile.email) {
                    this.$toast.error(this.$store.state.translation.axios.fix_fields)
                    this.errors.profile.email = this.$store.state.translation.axios.filed_required
                    return
                }
                if(!this.data.profile.username) {
                    this.$toast.error(this.$store.state.translation.axios.fix_fields)
                    this.errors.profile.username = this.$store.state.translation.axios.filed_required
                    return
                }
                if(this.data.profile.first_name && this.errors.profile.first_name) {
                    this.$toast.error(this.$store.state.translation.axios.fix_fields)
                    return
                }
                if(this.data.profile.last_name && this.errors.profile.last_name) {
                    this.$toast.error(this.$store.state.translation.axios.fix_fields)
                    return
                }
                if(this.data.profile.surname && this.errors.profile.surname) {
                    this.$toast.error(this.$store.state.translation.axios.fix_fields)
                    return
                }
                if(this.data.profile.sex && this.errors.profile.sex) {
                    this.$toast.error(this.$store.state.translation.axios.fix_fields)
                    return
                }
                if(this.data.profile.birthday && this.errors.profile.birthday) {
                    this.$toast.error(this.$store.state.translation.axios.fix_fields)
                    return
                }
                if(this.data.profile.phone.national && this.errors.profile.phone) {
                    this.$toast.error(this.$store.state.translation.axios.fix_fields)
                    return
                }

                this.loader_button = true

                this.$axios.post('/users/update', JSON.stringify({
                    email: this.data.profile.email,
                    username: this.data.profile.username,
                    personal: {
                        first_name: this.data.profile.first_name ? this.data.profile.first_name : null,
                        last_name: this.data.profile.last_name ? this.data.profile.last_name : null,
                        surname: this.data.profile.surname ? this.data.profile.surname : null,
                        sex: this.data.profile.sex ? this.data.profile.sex.key : null,
                        birthday: this.data.profile.birthday ? this.data.profile.birthday : null,
                        phone: {
                            country_code: this.data.profile.phone.data && this.data.profile.phone.data.countryCode ? this.data.profile.phone.data.countryCode : null,
                            international: this.data.profile.phone.data && this.data.profile.phone.data.formatInternational ? this.data.profile.phone.data.formatInternational : null,
                            national: this.data.profile.phone.data && this.data.profile.phone.data.formatNational ? this.data.profile.phone.data.formatNational : null
                        }
                    }
                }))
                .then((response) => {
                    this.loader_button = false
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':

                            this.$toast.success(response.message)

                            this.setUser(response.user)
                            
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.axios.unknown_error)
                    }
                }).catch((error) => {
                    this.loader_button = false

                    let response = error.response.data
                    this.setUser(response.user)

                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                        if('field' in response) {
                            this.errors.profile[response.field] = response.message
                        }
                    }
                })
            },
            async getCountries () {
                await this.$axios.post('/utils/countries/items')
                .then((response) => {
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':

                            for(let item in response.data) {
                                this.utils.countries.push({
                                    name: response.data[item],
                                    key: item
                                })
                            }

                            this.setUser(response.user)
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.axios.unknown_error)
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)
                    
                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })     
            },
            async setUserInfo() {
                this.data.profile = {
                    username: this.$store.state.user.data.username,
                    email: this.$store.state.user.data.email,
                    first_name: this.$store.state.user.data.personal.first_name,
                    last_name: this.$store.state.user.data.personal.last_name,
                    surname: this.$store.state.user.data.personal.surname,
                    sex: this.utils.sex.find(row => row.key == this.$store.state.user.data.personal.sex),
                    birthday: this.$store.state.user.data.personal.birthday ? this.$moment(this.$store.state.user.data.personal.birthday).format('Y-MM-DD') : null,
                    phone: {
                        country_code: this.$store.state.user.data.personal.phone.country_code,
                        national: this.$store.state.user.data.personal.phone.national,
                        data: null
                    }
                }

                if(!this.utils.countries.length) {
                    await this.getCountries()
                }

            },
            setAvatar (e) {
                var file = e.target.files[0]

                if(file) {
                    this.avatar.name = null
                    this.avatar.file = null

                    if (file.size > 5000000) {
                        this.errors.avatar = this.$store.state.translation.profile.personal.size_mb
                        return
                    }

                    var th = this
                    var reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onload = (e) => {
                        var image = new Image()
                        image.src = e.target.result
                        image.onload = () => {
                          var {height, width} = image
                          if (height < 50 || width < 50) {
                            th.errors.avatar = this.$store.state.translation.profile.personal.size1
                            return false
                          } else if (height > 2000 || width > 2000) {
                            th.errors.avatar = this.$store.state.translation.profile.personal.size2
                            return false
                          } else if (height != width) {
                            th.errors.avatar = this.$store.state.translation.profile.personal.size3
                            return false
                          }
                          th.errors.avatar = false
                          return true
                        }
                    }

                    this.avatar.name = file.name
                    this.avatar.file = file
                }
            },
            updateAvatar () {
                if(!this.avatar.file) {
                    this.errors.avatar = this.$store.state.translation.axios.filed_required
                    return
                }

                var formData = new FormData()
                formData.append('avatar', this.avatar.file)

                this.avatar_preloader = true
                this.$axios.post('/users/avatar/upload', formData)
                .then((response) => {
                    this.avatar_preloader = false
                    response = response.data
                    switch (response.status) {
                        case "error":
                            this.$toast.error(response.message)
                            break
                        case "success":

                            this.$toast.success(response.message)

                            this.setUser(response.user)
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.axios.unknown_error)
                    }
                }).catch((error) => {
                    this.avatar_preloader = false
                    let response = error.response.data
                    this.setUser(response.user)

                    if('status' in response && response.status == 'error') {
                        if('field' in response) {
                            this.errors[response.field] = response.message
                        } else {
                            this.$toast.error(response.message)
                        }
                    }
                })
            },
            birthdayValidate() {
                if(!this.data.profile.birthday) {
                    this.errors.profile.birthday = false
                    return
                }

                var eighteenYearsAgo = this.$moment().subtract(18, 'years')
                var birthday = this.$moment(this.data.profile.birthday)

                if (!birthday.isValid()) {
                    this.errors.profile.birthday = this.$store.state.translation.profile.personal.invalid_date
                } else if(this.data.profile.birthday.length != 10) {
                    this.errors.profile.birthday = this.$store.state.translation.profile.personal.invalid_date
                } else if (eighteenYearsAgo.isAfter(birthday)) {
                    this.errors.profile.birthday = false
                } else {
                    this.errors.profile.birthday = this.$store.state.translation.profile.personal.age
                }
            },
            phoneValidate(e) {
                if(e.isValid) {
                    this.errors.profile.phone = false
                } else {
                    this.errors.profile.phone = this.$store.state.translation.profile.personal.phone.invalid
                }

                if(!e.phoneNumber) {
                    this.errors.profile.phone = false
                }

                this.data.profile.phone.data = e
            }
        },
        beforeMount() { 
            if(this.$store.state.user.data.personal) {
                this.setUserInfo()
            }
        },
        watch: {
            '$store.state.user': function() {
                if(!this.data.profile.username) {
                    this.setUserInfo()
                }
            }
        }
    }
</script>

<style lang="css" scoped>
    ::v-deep .vs__clear {
        display: none;
    }
</style>