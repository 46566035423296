<template>
	
</template>

<script>
    export default {
        data() {
            return {
                data: {
                },
            }
        },
        methods: {
            check () {
                this.appLoader('create', 100)
                this.$axios.post('/auth/telegram/check', JSON.stringify({
                	initData: btoa(window.Telegram?.WebApp.initData)
                }))
                .then((response) => {
                    this.appLoader('remove')
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':

                            localStorage.setItem('token', response.token)
                            
                            this.$axios.defaults.headers.common.Authorization = 'Bearer ' + response.token

                            this.setUser(response.user)

                            this.$router.push({name: 'Home'})
                            
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.axios.unknown_error)
                    }
                }).catch((error) => {
                	if (error.response.status == 404) {
                		this.appLoader('remove')
                		return this.$router.push({name: 'Home', params: {popup: 'signup_telegram'}})
                	}

                    let response = error.response.data
                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            },
        },
        beforeMount () {
        	/*window.Telegram?.WebApp.expand()
		    window.Telegram?.WebApp.disableVerticalSwipes()
		    window.Telegram?.WebApp.enableClosingConfirmation()
		    window.Telegram?.WebApp.setHeaderColor('#140f2f')
		    window.Telegram?.WebApp.setBackgroundColor('#140f2f')
		    window.Telegram?.WebApp.setBottomBarColor('#140f2f')

		    window.Telegram?.WebApp.onEvent('backButtonClicked', () => {
				this.$router.push({name: 'Home'}) 
			})*/

            this.check()
        }
    }
</script>